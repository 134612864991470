import { gsap, ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger)

export default class LandingPageLinks {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }

    this.DOM.links = [...this.DOM.el.querySelectorAll(".js-links")]

    this.init()
  }

  onResize() {}

  navigateToSections(e) {
    e.preventDefault()

    // get section
    let section = document.querySelector(e.currentTarget.dataset.target)

    // if link isnt realted to apge section return
    if (!section) return (window.location.href = e.currentTarget.href)

    // scroll to section using native browser APIs
    window.lenis
      ? window.lenis.scrollTo(section, {
          duration: 0.66,
          offset: -(window.innerHeight / 4),
        })
      : section.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        })
  }

  gutenberg() {
    // if (window.acf) {
    //     this.init()
    // }
  }
  addEventListeners() {
    this.DOM.links.forEach((item) => {
      item.addEventListener("click", this.navigateToSections.bind(this))
    })
  }
  init() {
    //  console.log(this)
    this.addEventListeners()
    this.onResize()
  }
}
