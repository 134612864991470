export default class Header {
  constructor() {
    this.DOM = { el: document.querySelector("header") }
    this.DOM.headerType = this.DOM.el.querySelector(".header-type")
    this.DOM.toggleIcon = this.DOM.el.querySelector(".menu_toggle")
    this.DOM.toggleMenuIcon = this.DOM.el.querySelector(".menu-button")
    this.DOM.toggleMenuText = this.DOM.el.querySelector(".menu-button-text")
    this.DOM.mobileMenu = this.DOM.el.querySelector(".mobile-sidebar")
    this.DOM.pageBackground = document.querySelector(".page-cover")
    this.DOM.fullScreenNav = this.DOM.el.querySelector(".fullscreen-nav-js")
    this.DOM.revealActive = this.DOM.el.classList.contains(
      "header__activate_scrolling"
    )
    this.DOM.menuItem = [
      ...document.querySelectorAll(
        ".menu-item-has-children a:not(.sub-menu a)"
      ),
    ]
    this.DOM.menuItemHasChildren = [
      ...document.querySelectorAll(".menu-item-has-children"),
    ]
    this.DOM.subMenus = [...document.querySelectorAll(".sub-menu")]
    this.screenLarge = window.matchMedia("(min-width: 992px)")
    this.lastScroll = 0
    this.windowX = null
    this.winY = null
    this.init()
  }

  toggleSubMenu(event) {
    const icon = event.currentTarget
    event.preventDefault()

    const currentSubMenu = icon.parentNode.querySelector(".sub-menu")

    if (this.DOM.headerType.classList.contains("logo_left")) {
      // Logo Left
    } else if (this.DOM.headerType.classList.contains("logo_middle")) {
      if (Array.isArray(this.DOM.subMenus)) {
        // close all other subMenus when selecting a new submenu
        this.DOM.subMenus.forEach((menu) => {
          menu.classList.remove("active-menu")
        })

        this.closeDropdowns()

        // on fullscreen with multiple dropdowns choose selected dropdowns
        if (icon.classList.contains("open-menu")) {
          icon.classList.remove("open-menu")
        } else {
          icon.classList.add("open-menu")
        }
      }
    } else {
    }

    //  console.log(icon)

    //show or hide each menu
    if (icon.classList.contains("js-open")) {
      currentSubMenu.classList.remove("active-menu")
      icon.classList.remove("js-open")
      //   console.log('x')

      // on first click fullscreen nav menu with multiple submenus
    } else if (
      icon.classList.contains("open-menu") &&
      !icon.classList.contains("js-open")
    ) {
      currentSubMenu.classList.add("active-menu")
      icon.classList.add("js-open")
      // console.log('g')
    } else if (
      !icon.classList.contains("js-open") &&
      this.DOM.headerType.classList.contains("logo_middle") &&
      Array.isArray(this.DOM.subMenus)
    ) {
      currentSubMenu.classList.remove("active-menu")
      icon.classList.remove("js-open")
      // console.log('z')
    } else {
      currentSubMenu.classList.add("active-menu")
      icon.classList.add("js-open")
      // console.log('gvvcc')
    }
  }

  init() {
    this.createDropdownIcon()
    this.eventListeners()
  }

  createDropdownIcon() {
    if (this.DOM.menuItem) {
      if (Array.isArray(this.DOM.menuItem)) {
        // an array
        this.DOM.menuItem.forEach((item) => {
          // create a plus span
          const plus = `<button class='sm-dropdown'></button>`
          item.insertAdjacentHTML("afterend", plus)
        })
      } else {
        // a single element
        // create a plus span
        const plus = `<button class='sm-dropdown'></button>`
        this.DOM.menuItem.insertAdjacentHTML("afterend", plus)
      }
    }
  }

  removeCover() {
    if (this.screenLarge.matches) {
      this.DOM.pageBackground.classList.remove("page-cover-opacity")
    }
  }

  eventListeners() {
    if (this.DOM.toggleMenuIcon) {
      this.DOM.toggleMenuIcon.addEventListener(
        "click",
        this.toggleMenu.bind(this)
      )
    }
    window.addEventListener("resize", this.removeCover.bind(this))

    if (this.DOM.menuItem) {
      if (Array.isArray(this.DOM.menuItem)) {
        // an array
        this.DOM.menuItemHasChildren.forEach((element) => {
          const icon = element.querySelector(".sm-dropdown")
          if (icon) {
            icon.addEventListener("click", this.toggleSubMenu.bind(this))
          }
        })
      } else {
        // a single element
        this.DOM.menuItemHasChildren
          .querySelector(".sm-dropdown")
          .addEventListener("click", this.toggleSubMenu.bind(this))
      }
    }
  }

  toggleMenu() {
    this.DOM.toggleIcon.classList.toggle("toggle_on")
    this.DOM.toggleMenuText.classList.toggle("open")

    // IF we have logo - left header selected
    if (this.DOM.mobileMenu) {
      this.DOM.mobileMenu.classList.toggle("mobile-menu-appear")
      this.DOM.pageBackground.classList.toggle("page-cover-opacity")
      //   this.DOM.page.classList.toggle('paged');
    }
    if (this.DOM.headerType.classList.contains("logo_left")) return
    // IF we have logo middle selected
    // This will work if scrolling header is remvoed otherwise please check below in scorlling function
    if (this.DOM.fullScreenNav.classList.contains("opacity-full")) {
      this.DOM.fullScreenNav.classList.remove("opacity-full")
      // Remove listener to re-enable scroll
      document.querySelector("body").style.overflow = "visible"
    } else {
      this.DOM.fullScreenNav.classList.add("opacity-full")
      // disable scroll when fullscreen nav is visible
      document.querySelector("body").style.overflow = "hidden"
    }
  }

  closeDropdowns() {
    const dropdwns = [...document.querySelectorAll(".sm-dropdown")]
    dropdwns.forEach((toggle) => toggle.classList.remove("js-open"))
  }

  scrolling(e) {
    // WHEN YOU SCROLL DOWN HIDE HEADER, REVEAL WHEN SCROLLING UP
    // accessed through index.js hence why we need to use 'header.'

    let currentScroll = document.body.getBoundingClientRect().top

    if (this.header.DOM.revealActive != null) {
      if (currentScroll === 0)
        return this.header.DOM.el.classList.remove("js-hide-header")

      currentScroll > this.header.lastScroll
        ? this.header.DOM.el.classList.add("js-hide-header")
        : this.header.DOM.el.classList.remove("js-hide-header")

      this.header.lastScroll = currentScroll
    }
  }
}
