import imagesLoaded from "imagesloaded"
import EventEmitter from "../Classes/EventEmitter"
// import gsap from "gsap";

export default class Preloader extends EventEmitter {
  constructor() {
    super()
    this.DOM = { el: document.querySelector(".pre_loader") }
    this.DOM.images = [...this.DOM.el.querySelectorAll("img")]
    this.length = 0
    this.imageLoader()
  }

  imageLoader() {
    const imgLoad = imagesLoaded(this.DOM.images)
    imgLoad.on("progress", (instance, img) => {
      this.onImagesLoaded(instance, img)
    })
  }

  onImagesLoaded(instance, img) {
    //   console.log( 'this image is loaded', img)
    // after we have loaded all the images wait 2 seconds before removing preloader
    this.emit("completed", "yes")
  }

  destroy() {
    this.DOM.el.classList.add("hidden__preloader")
    setTimeout(() => {
      // this.DOM.el.parentNode.removeChild(this.DOM.el)
    }, 2000)
  }
}
