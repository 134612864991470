// Hero
import HeroSlideshow from '../Blocks/hero/HeroSlideshow'
import HeroFullWidth from '../Blocks/hero/HeroFullWidth'

export default class HeroManager {
  constructor({ hero, header, screenSize, preloader }) {
    this.hero = hero
    this.header = header
    this.screenSize = screenSize
    this.preloader = preloader

    if (this.hero) {
      this.currentHeroValue = this.hero.getAttribute('data-hero')
      this.init()
    } else {
      //If hero not available doing something, this.header.headerBackground()
    }
  }

  init() {
    this.heroCheck(this.currentHeroValue)
  }

  heroCheck(hero) {
    // split classnames into an array
    let heroClassnames = hero.split(' ')
    heroClassnames = heroClassnames.filter(
      (cl) => cl !== 'alignfull' || !cl.includes('wp-block')
    )
    // flatten array
    this.currentHeroValue = heroClassnames[0]

    switch (this.currentHeroValue) {
      case 'HeroFullWidth':
        this.theHero = new HeroFullWidth()
        break
      case 'HeroSlideshow':
        this.theHero = new HeroSlideshow()
        break
    }

    // if gutenberg
    if (typeof wp != 'undefined' && wp.blockEditor) {
  
      if (this.theHero.gutenberg) {
        this.theHero.gutenberg()
      }
   
    } else {
      this.theHero.create()
    }
  }

  deleteHero() {
    if (this.theHero) {
      delete this.theHero
    }
  }

  // barba enter
  enterPageTransition() {
    this.currentHero = document.querySelector('[data-hero]')
    if (this.currentHero) {
      this.currentHeroValue = this.currentHero.getAttribute('data-hero')
    }

    this.heroCheck(this.currentHeroValue)
  }

  // does block need to enable gutenberg  back end editing
  gutenberg() {
    if (this.hero.gutenberg) {
      this.hero.gutenberg()
    }
  }

  onResize(screenSize) {
    this.screenSize = screenSize

    // console.log(this)
    //  console.log('heros resize')
  }
}
