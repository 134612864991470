import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)

export default class List{
    constructor (id) {
        this.DOM = {el: document.getElementById(id)}
        this.DOM.items = [...this.DOM.el.querySelectorAll('.list__item')]
        this.init()
    }

    showList(event) {
        const item = event.target.closest('.list__item')
        const desc = item.querySelector('.list__item_desc')
        // get icon div
        const icon = item.querySelector('.list__item_title .list__item_span')
        //get a perfect slide down 
        if (desc.offsetHeight > 0) {
            // console.log('scrollUp')
            desc.style.height = `0px`;
            // toggle between down arrow and straight bullet point
            icon.children[0].classList.remove('js_list__left')
            icon.children[1].classList.remove('js_list__right')

        } else {
            // console.log('scrollDown')
            desc.style.height = `${desc.scrollHeight}px`;
            // toggle between down arrow and straight bullet point
            icon.children[0].classList.add('js_list__left')
            icon.children[1].classList.add('js_list__right')
        }
    }
    onResize () { }
    gutenberg() {
        if (window.acf) {
            this.init()
        }
    }
    addEventListeners() {
        this.DOM.items.forEach(item => {
            item.addEventListener('click', this.showList.bind(this))
        })
    }
    init() {
      //  console.log(this)
        this.addEventListeners()
        this.onResize()
     }
}