import { gsap, SplitText } from "gsap/all";
gsap.registerPlugin(SplitText)

// This Variable splits a title into lines and fades it up

export const SplitTitle = title => {
    const newTitle =  new SplitText(title, {type: "lines", linesClass: "lineChild"});
    const innerTitle =  new SplitText(title, {type: "lines", linesClass: "lineParent"});
    gsap.set(newTitle.lines, { yPercent: 100})

   return newTitle.lines

  //  return gsap.timeline({
  //       defaults: {
  //           ease: "expo",
  //           duration: 0.8,
  //           transformOrigin: '0 50%'
  //       }})
  //       .fromTo(this.title.lines, { 
  //       yPercent: 150,
  //       rotate: 15,
  //     }, {
  //       yPercent: 0,
  //       rotate: 0,
  //     });
};