import { gsap } from "gsap/all"
import Swiper from "swiper"
import { Navigation, Pagination, Scrollbar, FreeMode } from "swiper/modules"
// import "swiper/css"

export default class MeetTheTeam {
  constructor({ id, cursor }) {
    this.DOM = { el: document.getElementById(id) }
    this.matchMedia = gsap.matchMedia()
    this.body = document.querySelector("body")
    this.cursor = cursor

    this.currentMemberInfo = {}
    this.mq = gsap.matchMedia()

    this.DOM.items = [...this.DOM.el.querySelectorAll(".js-team-item")]
    this.DOM.galleryItems = [
      ...this.DOM.el.querySelectorAll(".js-gallery-team-item"),
    ]
    this.DOM.gridItems = [...this.DOM.el.querySelectorAll(".js-grid-team-item")]

    this.DOM.selectors = [...this.DOM.el.querySelectorAll(".js-view-selectors")]

    //filters
    this.DOM.filters = this.DOM.gridItems.map((item) => item.dataset.permalink)

    // Gallery / Grid Selector
    this.DOM.masterContainer = document.querySelector(".js-master-container")
    this.DOM.gridView = document.querySelector(".js-grid-view")
    this.DOM.galleryView = document.querySelector(".js-gallery-view")
    this.DOM.gridButton = document.querySelector(".js-button-grid")
    this.DOM.galleryButton = document.querySelector(".js-button-gallery")

    // swiper slider
    this.DOM.slider = this.DOM.el.querySelector(".js-gallery-slider")
    this.DOM.galleryImages = [
      ...this.DOM.el.querySelectorAll(".js-gallery-image"),
    ]
    this.DOM.scrollbar = this.DOM.el.querySelector(".js-gallery-scrollbar")

    // Popup
    this.DOM.teamViewer = { el: document.querySelector(".js-teamviewer") }
    this.DOM.teamViewer.wrapper = document.querySelector(".js-wrapper")
    this.DOM.teamViewer.overlay = document.querySelector(".js-overlay")
    this.DOM.teamViewer.panelLeft =
      this.DOM.teamViewer.el.querySelector(".js-left-panel")
    this.DOM.teamViewer.panelRight =
      this.DOM.teamViewer.el.querySelector(".js-right-panel")

    this.DOM.teamViewer.image =
      this.DOM.teamViewer.el.querySelector(".js-image")
    this.DOM.teamViewer.text = this.DOM.teamViewer.el.querySelector(
      ".js-teamviewer-text"
    )
    this.DOM.teamViewer.close =
      this.DOM.teamViewer.wrapper.querySelector(".js-close")
    this.DOM.teamViewer.name = this.DOM.teamViewer.el.querySelector(".js-name")

    this.DOM.teamViewer.position =
      this.DOM.teamViewer.el.querySelector(".js-position")
    this.DOM.teamViewer.container = this.DOM.teamViewer.el.querySelector(
      ".js-teamviewer-container"
    )

    this.DOM.teamViewer.button =
      this.DOM.teamViewer.wrapper.querySelector(".js-arrow-button")
    this.DOM.teamViewer.qualification =
      this.DOM.teamViewer.el.querySelector(".js-qual")
    this.DOM.teamViewer.desc = this.DOM.teamViewer.el.querySelector(".js-desc")
    this.DOM.teamViewer.arrowNext =
      this.DOM.teamViewer.wrapper.querySelector(".js-next")
    this.DOM.teamViewer.arrowPrev =
      this.DOM.teamViewer.wrapper.querySelector(".js-prev")

    // popup progressbar
    this.DOM.teamViewer.progressBar =
      this.DOM.teamViewer.wrapper.querySelector(".js-progress-bar")
    this.DOM.teamViewer.progressBarTrack =
      this.DOM.teamViewer.wrapper.querySelector(".js-progress-track")
    this.DOM.teamViewer.progressBarThumbnail =
      this.DOM.teamViewer.wrapper.querySelector(".js-progress-thumbnail")

    this.currentTeamMember = null
    this.init()
  }

  showTooltip(event) {
    this.cursor.showGallerySlider()

    // desktop
    this.mq.add("(min-width: 768px)", () => {
      this.cursor.showGallerySlider()
    })
  }

  hideTooltip() {
    this.cursor.hideGallerySlider()
  }

  swiper() {
    // https://gambitresort.webflow.io/about

    this.swiper = new Swiper(this.DOM.slider, {
      direction: "horizontal",
      keyboard: true,
      speed: 800,
      modules: [Navigation, Pagination, Scrollbar, FreeMode],
      mousewheel: {
        forceToAxis: true,
      },
      scrollbar: {
        el: this.DOM.scrollbar,
        draggable: true,
      },
      touchEventsTarget: "container",
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 10,
          freeMode: {
            enabled: false,
            minimumVelocity: 0.65,
            sticky: true,
          },
        },
        768: {
          slidesPerView: 2,
          slidesOffsetAfter: 0,
          freeMode: {
            enabled: false,
            minimumVelocity: 0.65,
            sticky: true,
          },
        },
        992: {
          slidesPerView: 3.5,
          freeMode: {
            enabled: true,
            minimumVelocity: 0.65,
            sticky: true,
          },
        },
        1200: {
          slidesPerView: 4.125,
          spaceBetween: 0,
          freeMode: {
            enabled: true,
            minimumVelocity: 0.65,
            sticky: true,
          },
        },
        1920: {
          slidesPerView: 4.5,
          spaceBetween: 0,
          freeMode: {
            enabled: true,
            minimumVelocity: 0.65,
            sticky: true,
          },
        },
      },
    })

    let myTimeout

    this.swiper.on("progress", () => {
      clearTimeout(myTimeout)

      this.DOM.galleryImages.map((image) => image.classList.add("js-scale"))

      this.hideTooltip()

      myTimeout = setTimeout(() => {
        this.DOM.galleryImages.map((image) =>
          image.classList.remove("js-scale")
        )
      }, 100)
    })
  }

  create() {
    gsap.set(this.DOM.teamViewer.text, { autoAlpha: 0 })
    gsap.set(this.DOM.teamViewer.panelLeft, { xPercent: 100 })
    gsap.set(this.DOM.teamViewer.panelRight, { xPercent: -100 })
    gsap.set(this.DOM.teamViewer.overlay, { autoAlpha: 0 })
    gsap.set(
      [
        this.DOM.teamViewer.close,
        this.DOM.teamViewer.button,
        this.DOM.teamViewer.progressBar,
      ],
      {
        autoAlpha: 0,
      }
    )
    gsap.set([this.DOM.galleryView, this.DOM.gridView], {
      y: 100,
      autoAlpha: 0,
    })

    this.setInitialView()
  }

  setInitialView() {
    // check which view is active
    if (this.DOM.gridButton.classList.contains("js-active")) {
      this.DOM.masterContainer.style.height = `${this.DOM.gridView.clientHeight}px`

      setTimeout(() => {
        this.animateIn(this.DOM.gridView)
      }, 750)
    } else if (this.DOM.galleryButton.classList.contains("js-active")) {
      this.DOM.masterContainer.style.height = `${this.DOM.galleryView.clientHeight}px`

      setTimeout(() => {
        this.animateIn(this.DOM.galleryView)
      }, 750)
    } else {
    }
  }

  closePopup() {
    this.closeAnim = gsap.timeline({
      onComplete: () => {
        this.body.style.overflow = "visible"
        this.DOM.teamViewer.wrapper.classList.remove("js-active")

        // empty data
        this.cleanData()
      },
      onStart: () => {
        this.DOM.teamViewer.container.classList.remove("js-shadow")
      },
    })

    // Animation
    this.closeAnim
      .to(
        [
          this.DOM.teamViewer.progressBar,
          this.DOM.teamViewer.close,
          this.DOM.teamViewer.button,
        ],
        {
          autoAlpha: 0,
          stagger: 0.1,
          duration: 0.25,
        }
      )
      .to(
        this.DOM.teamViewer.panelRight,
        { xPercent: -100, ease: "power4.out", duration: 0.75 },
        0
      )
      .to(this.DOM.teamViewer.overlay, { autoAlpha: 0, duration: 0.5 }, 0)

    // mobile
    this.mq.add("(max-width: 767px)", () => {
      gsap.set(this.DOM.teamViewer.panelLeft, { clearProps: "all" })
    })

    //Desktop
    this.mq.add("(min-width: 768px)", () => {
      this.closeAnim.to(
        this.DOM.teamViewer.panelLeft,
        {
          xPercent: 100,
          ease: "power4.out",
          duration: 0.75,
        },
        0
      )
    })
  }

  updateProgressBar(target, modifier) {
    modifier = modifier || 0
    let index
    let length
    let sum

    if (target.dataset.itemType === "grid") {
      index = this.DOM.gridItems.indexOf(target) + 1
      length = this.DOM.gridItems.length
    } else {
      //gallery
      // target.dataset.itemType === "gallery"
      index = this.DOM.galleryItems.indexOf(target) + 1
      length = this.DOM.galleryItems.length
    }

    sum = index + modifier

    // when go backwards from first item, go to last item
    sum === 0 ? (sum = length) : (sum = sum)
    // when go forwards from last item, go to first item
    sum > length ? (sum = modifier || 0) : (sum = sum)

    // console.log("----------")
    // console.log("index:", index)
    // console.log("modifier:", modifier)
    // console.log("sum:", sum)
    // console.log("length:", length)

    this.DOM.teamViewer.progressBarThumbnail.style.width = `${
      (sum / length) * 100
    }%`
  }

  openPopup(target) {
    this.body.style.overflow = "hidden"
    this.DOM.teamViewer.wrapper.classList.add("js-active")
    this.currentTeamMember = target

    // update progress bar
    this.updateProgressBar(this.currentTeamMember)

    this.showTeam(this.currentTeamMember)

    this.openAnim = gsap.timeline({
      onStart: () => {
        setTimeout(() => {
          this.DOM.teamViewer.container.classList.add("js-shadow")
        }, 700)
      },
    })

    this.openAnim
      .to(
        this.DOM.teamViewer.panelRight,
        { xPercent: 0, ease: "power4.out", duration: 1.25 },
        0
      )
      .to(
        [
          this.DOM.teamViewer.close,
          this.DOM.teamViewer.button,
          this.DOM.teamViewer.progressBar,
        ],
        { autoAlpha: 1, duration: 0.5, stagger: 0.1 },
        0
      )
      .to(this.DOM.teamViewer.overlay, { autoAlpha: 1, duration: 0.5 }, 0)

    // desktop
    this.mq.add("(min-width: 768px)", () => {
      this.openAnim.to(
        this.DOM.teamViewer.panelLeft,
        { xPercent: 0, ease: "power4.out", duration: 1.25 },
        0
      )
    })
  }

  // constructor

  cleanData() {
    this.DOM.teamViewer.desc.innerHTML = ""
    this.DOM.teamViewer.qualification.innerHTML = ""
    this.DOM.teamViewer.image.src = ""
    this.DOM.teamViewer.name.innerHTML = ""
    this.DOM.teamViewer.position.innerHTML = ""
  }

  showNextTeam() {
    // get index position of current team member
    let index = this.DOM.items.indexOf(this.currentTeamMember)

    this.updateProgressBar(this.currentTeamMember, 1)

    // focus next button
    this.DOM.teamViewer.arrowNext.focus()

    this.showTeam(this.DOM.items[index + 1] || this.DOM.items[0])
  }
  showPrevTeam() {
    // get index position of current team member
    let index = this.DOM.items.indexOf(this.currentTeamMember)

    this.updateProgressBar(this.currentTeamMember, -1)

    // focus next button
    this.DOM.teamViewer.arrowPrev.focus()

    this.showTeam(
      this.DOM.items[index - 1] || this.DOM.items[this.DOM.items.length - 1]
    )
  }

  showTeam(teamMember) {
    this.cleanData()

    //collect member info
    this.currentMemberInfo = {
      name: teamMember.dataset.name,
      jobTitle: teamMember.dataset.title,
      image: teamMember.dataset.image,
      qualification: teamMember.dataset.qual,
      desc: JSON.parse(teamMember.dataset.desc),
      permalink: teamMember.dataset.permalink,
    }

    this.displayFilter(this.currentMemberInfo.permalink)

    //set member info
    this.DOM.teamViewer.name.innerHTML = this.currentMemberInfo.name
    this.DOM.teamViewer.position.innerHTML = this.currentMemberInfo.jobTitle
    this.DOM.teamViewer.image.src = this.currentMemberInfo.image

    if (this.currentMemberInfo.qualification)
      this.DOM.teamViewer.qualification.innerHTML =
        this.currentMemberInfo.qualification

    if (this.currentMemberInfo.desc.length > 0) {
      this.currentMemberInfo.desc.forEach((item) => {
        this.DOM.teamViewer.desc.innerHTML += `<p class='team__popup-description-text'>${item.paragraph}</p>`
      })
    }

    this.currentTeamMember = teamMember
  }

  resize() {
    this.closePopup()
    this.setInitialView()
  }

  changeView(e) {
    let target
    e.currentTarget ? (target = e.currentTarget) : (target = e)

    // if already selected return
    if (target.classList.contains("js-active")) return

    // if not selected, select correct view
    target.dataset.view === "gallery"
      ? this.showGalleryView()
      : this.showGridView()
  }

  showGalleryView() {
    //gallery
    this.DOM.gridButton.classList.remove("js-active")
    this.DOM.galleryButton.classList.add("js-active")

    this.DOM.slider.classList.remove("js-remove-pointer-events")

    this.DOM.masterContainer.style.height = `${this.DOM.galleryView.clientHeight}px`
    this.animateIn(this.DOM.galleryView, this.DOM.gridView)
  }

  showGridView() {
    //grid
    this.DOM.gridButton.classList.add("js-active")
    this.DOM.galleryButton.classList.remove("js-active")

    this.DOM.slider.classList.add("js-remove-pointer-events")

    this.DOM.masterContainer.style.height = `${this.DOM.gridView.clientHeight}px`
    this.animateIn(this.DOM.gridView, this.DOM.galleryView)
  }

  addEventListeners() {
    this.closeEvent = this.closePopup.bind(this)

    this.DOM.items.forEach((item) => {
      item.addEventListener("click", (e) => this.openPopup(e.currentTarget))
    })

    this.DOM.teamViewer.close.addEventListener("click", this.closeEvent)
    this.DOM.teamViewer.wrapper.addEventListener(
      "click",
      this.handleClickOutside.bind(this)
    )
    window.addEventListener("keyup", this.handleKeyUp.bind(this))
    this.DOM.teamViewer.arrowNext.addEventListener(
      "click",
      this.showNextTeam.bind(this)
    )
    this.DOM.teamViewer.arrowPrev.addEventListener(
      "click",
      this.showPrevTeam.bind(this)
    )

    window.addEventListener("resize", this.resize.bind(this))
    this.DOM.gridButton.addEventListener("click", this.changeView.bind(this))
    this.DOM.galleryButton.addEventListener("click", this.changeView.bind(this))

    // Cursor
    this.DOM.slider.addEventListener("mousemove", this.showTooltip.bind(this))
    this.DOM.slider.addEventListener("mouseleave", this.hideTooltip.bind(this))
  }

  handleKeyUp(event) {
    if (event.key === "Escape") return this.closePopup()
    if (event.key === "ArrowRight") return this.showNextTeam()
    if (event.key === "ArrowLeft") return this.showPrevTeam()
    if (event.key === "1") return this.changeView(this.DOM.selectors[0])
    if (event.key === "2") return this.changeView(this.DOM.selectors[1])
  }

  handleClickOutside(e) {
    if (e.target === e.currentTarget) return this.closePopup()
  }

  gutenberg() {
    if (window.acf) {
      // dont run in gutenberg
      console.log("gutenberg team")
      // this.init()
    }
  }

  animateIn(activeElement, nonActiveElement) {
    this.animateInTL = gsap.timeline({
      defaults: {
        ease: "power4.out",
        duration: 0.75,
      },
      onComplete: () => {
        gsap.set(activeElement, { clearProps: "all" })
      },
    })

    this.animateInTL.to(activeElement, { y: 0, autoAlpha: 1 }, 0)
    // .to(
    //   activeElement,
    //   { autoAlpha: 0, duration: 0.75, ease: "power4.out" },
    //   0
    // )

    if (nonActiveElement)
      this.animateInTL.to(nonActiveElement, { y: 100, autoAlpha: 0 }, 0)
    // .to(
    //   nonActiveElement,
    //   { autoAlpha: 0, duration: 0.75, ease: "power4.out" },
    //   0
    // )
  }

  checkForFilter() {
    // check if filter is in url
    const url = new URL(window.location.href)
    const filter = url.pathname

    this.DOM.filters.forEach((item) => {
      // if string item contains filter
      if (item.includes(filter)) {
        // find index of item
        let index = this.DOM.filters.indexOf(item)
        this.openPopup(this.DOM.gridItems[index])
      }
    })
  }

  displayFilter(permalink) {
    // update url
    window.history.pushState("", "", permalink)
  }

  // called automatically
  init() {
    this.create()
    this.swiper()
    this.addEventListeners()
    this.checkForFilter()
    //   this.onResize()
  }
}
