import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)
import { isInViewport } from '../../Utils'

export default class Timeline {
    constructor ({ scroll, container, screenSize, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.screenSize = screenSize
        this.DOM.container = this.DOM.el.querySelector('.case-group')
        this.DOM.items = [...this.DOM.el.querySelectorAll('.scroll-text-box')]
        this.DOM.images = [...this.DOM.el.querySelectorAll('.case_item__image')]
        this.DOM.scrollTabs = [...this.DOM.el.querySelectorAll('.scroll--menu_item')]
        this.DOM.caseTitles = [...this.DOM.el.querySelectorAll('.case__title__title')]
        this.DOM.caseDesc = [...this.DOM.el.querySelectorAll('.case__desc__desc')]
        this.DOM.sections = [...this.DOM.el.querySelectorAll('.case__section')]
        this.itemAnim = []
        this.imageAnim = []

  

       this.init()
    }

    titlesIn() {
      //  console.log('hl')
        gsap.set([this.DOM.caseTitles], { yPercent: 100 })
        gsap.set([this.DOM.caseDesc], {autoAlpha: 0})
        this.DOM.items.forEach(section => { 
           const itemAnim = gsap.to([section.querySelector('.case__title__title'), section.querySelector('.case__desc__desc')], {
                scrollTrigger: {
                    trigger: section,
                    start: "top center",
                    end: 'bottom center',
                    once: true,
                  //  markers: true
                },
                yPercent: 0,
                duration: 1,
                autoAlpha: 1,
                ease: "Power4.easeOut.easeOut",
                stagger: 0.25
            })

            this.itemAnim.push(itemAnim)
    })
}

    fade() {
        gsap.set([this.DOM.images], {autoAlpha: 0})

        this.DOM.images.forEach(section => { 
            const imageAnim =  gsap.to(section, {
                scrollTrigger: {
                    trigger: section,
                    start: "center center",
                    end: 'bottom center',
                  //  markers: true,
                    scrub: true
                },
                autoAlpha: 1
            })
            this.imageAnim.push(imageAnim)
        })
    }

    animate() {
        this.DOM.items.forEach(section => {
            if (isInViewport(section)) {
                for (var i = 0; i < this.DOM.images.length; i++) {
                    let dataImage = this.DOM.images[i].getAttribute("data-image");
                    let dataSection = section.getAttribute("data-section");
                    this.DOM.images[i].classList.remove("js-item_visible");
                    this.DOM.scrollTabs[i].classList.remove("js-tab-visible");
    
                    if (dataImage == dataSection) {
                        this.DOM.images[i].classList.add("js-item_visible");
                        this.DOM.scrollTabs[i].classList.add("js-tab-visible");
                    }
                }
            }
        })
    }

    addEventlisteners() {
      document.addEventListener('scroll', this.animate.bind(this) )
    }

    // run on resize
    onResize (screensized) {
        // Check if we are running on page load or on screen resize
        let screen;
        screensized ? screen = screensized : screen = this.screenSize

      //  console.log(this)

        if (screen === 'mobile' || screen === 'tablet') {


            // dOESN'T WORK
        // this.DOM.sections.forEach(section => {
        //    const title =  "." + section.querySelector('.case__title__title').className
        //    const desc = "." + section.querySelector('.case__desc__desc').className
        //    const image = "." + section.querySelector('.case_item__image').className

        //    gsap.killTweensOf(title, desc, image) 

        // })
               
             
              //  this.imageAnim.destroy()
        } else {
             //   console.log('desktop')
                ScrollTrigger.refresh()
        }
    }
    
    init() {
        this.fade()
        this.titlesIn()
        this.addEventlisteners()
    }
}