import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)
import { halfHeight } from '../../Utils'

export default class FAQ {
    constructor ({ scroll, container, screenSize, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.screenSize = screenSize
        this.DOM.menu = this.DOM.el.querySelector('.ns-faq-main-nav')
        this.DOM.answer = this.DOM.el.querySelector('.faq-qa-inner')
        this.DOM.innerBar = this.DOM.el.querySelector('.ns-nav-faqs')
        this.DOM.questions = [...this.DOM.el.querySelectorAll('.faq-sm-link')]
        this.DOM.topLevels = [...this.DOM.el.querySelectorAll('.top-level details')]
        this.DOM.stages = [...this.DOM.el.querySelectorAll('.faq-qa-item')]
   
       this.init()
    }

    linkTo () {
       this.DOM.stages.forEach( stage => {
            // when we click on a link scroll to that section
            this.DOM.questions.forEach(link => {
                const target = link.getAttribute('href')
                link.addEventListener('click', (e) => {
                    e.preventDefault()

                   // console.log(target, link, stage)
                    document.querySelector(target).scrollIntoView({block: "center"})
                  //  this.scroll.scroll.scrollTo(target, {offset: -halfHeight(stage)})
                })
            })
        })
    }

    collapse() {
        // close all tabs when opening a new tab
             // Add the onclick listeners.
             this.DOM.topLevels.forEach((targetDetail) => {
                 targetDetail.addEventListener("click", () => {
                 // Close all the details that are not targetDetail.
                 this.DOM.topLevels.forEach((detail) => {
                     if (detail !== targetDetail) {
                     detail.removeAttribute("open")
                     }
                 })
                 })
             })
         }

    fixInPlace () {
    gsap.to(this.DOM.menu, {
        scrollTrigger: {
            trigger: this.DOM.answer,
            start: "top top+=10%",
            end: "end center",
            pin: true,
          //  markers: true
        },
        ease: "Power4.easeOut.easeOut",
    })


}
  init () {
  this.fixInPlace()
  this.collapse()
  this.linkTo()
}
}

