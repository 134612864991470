import Swiper from "swiper"
import {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  FreeMode,
} from "swiper/modules"

export default class LandingPageTestimonials {
  constructor(id) {
    this.DOM = { el: document.getElementById(id) }
    this.DOM.slider = this.DOM.el.querySelector(".js-swiper")
    this.DOM.items = [...this.DOM.el.querySelectorAll(".js-slide")]
    this.DOM.pagination = this.DOM.el.querySelector(".js-pagination")

    this.init()
  }

  swiperInit() {
    this.swiper = new Swiper(this.DOM.slider, {
      modules: [Navigation, Pagination, Autoplay],
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      grabCursor: true,
      loop: true,
      spaceBetween: 0,
      pagination: {
        el: this.DOM.pagination,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
        },
        1200: {
          slidesPerView: 3,
          centeredSlides: true,
          initialSlide: 1,
        },
        1920: {
          slidesPerView: 3,
          centeredSlides: true,
          initialSlide: 1,
        },
      },
    })
  }

  init() {
    this.swiperInit()
  }
}
