import { SplitTitle } from '../../Animations/SplitTitle'
import { gsap, DrawSVGPlugin, SplitText } from 'gsap/all'
gsap.registerPlugin(DrawSVGPlugin, SplitText)

export default class HeroFullWidth {
  constructor() {
    this.DOM = { el: document.querySelector('.HeroFullWidth') }
    this.DOM.subtitle = this.DOM.el.querySelector('.hfw-subtitle')
    this.DOM.title = this.DOM.el.querySelector('.hfw-title')
    this.DOM.desc = this.DOM.el.querySelector('.hfw-desc')
    this.DOM.image = this.DOM.el.querySelector('img')
    this.DOM.button = this.DOM.el.querySelector('.main-button')
  }

  create() {
    // this.title = SplitTitle(this.DOM.title)
    // this.subtitle = SplitTitle(this.DOM.subtitle)
    // gsap.set(this.DOM.image, { scale: 1.2 })
    // gsap.set([this.DOM.desc, this.DOM.button], { autoAlpha: 0 })

    // split text
    this.onResize()
  }

  animate() {
    // this.tlHero = gsap.timeline({
    //   defaults: {
    //     ease: 'expo.out',
    //     duration: 0.8
    //   }
    // })

    // this.tlHero
    //   .to([this.title, this.subtitle], {
    //     yPercent: 0,
    //     duration: 0.8,
    //     stagger: '0.2'
    //   })
    //   .to([this.DOM.desc, this.DOM.button], { autoAlpha: 1 })
    //   .to(this.DOM.image, { scale: 1, duration: 2, ease: 'power4.out' }, 0)
  }

  onResize() {}

  // Necessary to have, leave empty if not needed
  gutenberg() {}

  onLoad() {
    this.animate()
  }
}
