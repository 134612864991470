import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger)
// core version + navigation, pagination modules:
import Swiper, { Scrollbar, Autoplay } from 'swiper'
// configure Swiper to use modules
Swiper.use([Scrollbar, Autoplay])

export default class Testimonials  {
    constructor ({ scroll, container, id }) {
     this.DOM = {el: document.getElementById(id)}
     this.scroll = scroll
     this.container = container
     this.DOM.wrapper = this.DOM.el.querySelector('.ss-wrapper')
     this.DOM.slider = this.DOM.el.querySelector('.Slideshow-slider')
     this.DOM.scrollbar = this.DOM.el.querySelector('.swiper-scrollbar')
     this.DOM.items = [...this.DOM.el.querySelectorAll('.ss--item')]
     this.init()
    }

    swiperInit () {
        this.swiper = new Swiper(this.DOM.slider, {
          slidesPerView: 2,
          spaceBetween: 5,
          grabCursor: true,
          updateOnImagesReady: true,
          loop: true,
          freeMode: true,
          freeModeMomentumBounce: false,
          freeModeMomentumVelocityRatio: 0.3,
          scrollbar: {
           el: ".ss-scrollbar",
           draggable: true
          },
          autoplay: {
          delay: 2500,
          disableOnInteraction: false
          },
          breakpoints: {
               // when window width is >= 0px
            0: {
              slidesPerView: 1,
              spaceBetween: 5
            },
            // when window width is >= 640px
            768: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            // when window width is >= 640px
            1220: {
              slidesPerView: 2,
              spaceBetween: 30
            }
          }

      })
    }


   moveOnScroll() {
          // Scroll triggered movement
    const tl = new gsap.timeline();

    tl.set(this.DOM.slider, {willChange: "transform"});

    tl.fromTo(this.DOM.slider, {
        x: -300
    }, {
        x: 0,
        ease: "none"
    }, 0);

    // when you add multiple slideshows this should be selected for even index slideshows
    // tl.fromTo(carousel[1], {
    //     x: 300
    // }, {
    //     x: 0,
    //     ease: "none"
    // }, 0);

    tl.set(this.DOM.slider, {willChange: "auto"});

    ScrollTrigger.create({
        trigger: this.DOM.el,
        animation: tl,
        start: "top bottom",
        end: "bottom top",
        scrub: 0.3,
       // markers: true,
        refreshPriority: -14
    })

    }

  init () {
    this.swiperInit()
    this.moveOnScroll()
  }
  gutenberg() {
    if (window.acf) {
        this.init()
    }
  }

}


