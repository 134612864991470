import { Parallax } from "../../Animations/Parallax"

export default class FullWidthImage  {
    constructor ({ scroll, container, screenSize, id }) {
        this.DOM = {el: document.getElementById(id)}
        this.scroll = scroll
        this.container = container
        this.screenSize = screenSize
        this.DOM.image = this.DOM.el.querySelector('.parallax-image');
        this.init()
    }

    init() {
        Parallax(this.DOM.image)
    }

    onResize() {
        this.init()
    }

}

