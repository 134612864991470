// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from "swiper"

export default class Testimonials {
  constructor({ scroll, container, id }) {
    this.DOM = { el: document.getElementById(id) }
    this.scroll = scroll
    this.container = container
    this.DOM.slider = this.DOM.el.querySelector(".testimonial__container")
    this.DOM.items = [...this.DOM.el.querySelectorAll(".testimonial__slide")]

    this.init()
  }

  swiperInit() {
    this.swiper = new Swiper(this.DOM.slider, {
      slidesPerView: 1,
      spaceBetween: 30,
      modules: [Navigation, Pagination, Autoplay],
      simulateTouch: true,
      loop: true,
      pagination: {
        el: ".ts__swiper-pagination",
        dynamicBullets: true,
        clickable: true,
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    })
  }

  init() {
    this.swiperInit()
  }
}
